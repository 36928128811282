import {Level} from "../constans/levelTypes";
import {get} from "lodash";
import {createSelector} from "reselect";

const getBuildings = (state) =>
    state.farms.buildings

const getFarms = (state) =>
    state.farms.farms

const getClosestParams = (state, {id, level}={}) => {
    return {id, level};
}

const getPlacementID = (state, {PlcmntID}) => PlcmntID

/**
 * Mapa aktywnych budynkow - dla podanego id zwracany jest rodzaj budynku (level), id rodzica (parent) oraz nazwa (jako tablica name)
 * @type {OutputSelector<unknown, Map<any, any>, (res1: *, res2: *) => Map<any, any>>}
 */
export const getBuildingsMap = createSelector([getBuildings, getFarms], (_buildings, _farms) => {
    const map = new Map();
    console.log("getBuildingsMap selector called")
    _farms.forEach(farm => {
        map.set(farm.FarmID, {
            level: Level.FARM,
            parentId: farm.FarmID,
            name: [farm.FarmName],
            id: farm.FarmID
        })
    })
    _buildings.forEach(building => {
        map.set(building.BgID, {
            level: Level.BUILDING,
            parentId: building.FarmID,
            name: [building.BName],
            id: building.BgID
        })
        get(building, "Sectors", []).forEach(sector => {
            map.set(sector.SID, {
                level: Level.SECTOR,
                parentId: building.BgID,
                name: [building.BName, sector.SName],
                id: sector.SID,
                SType: sector.SType
            })
            get(sector, "Chambers", []).forEach(chamber => {
                map.set(chamber.CID, {
                    level: Level.CHAMBER,
                    parentId: sector.SID,
                    name: [building.BName, sector.SName, chamber.CName],
                    id: chamber.CID,
                    SType: sector.SType
                })
                get(chamber, "Boxes", []).forEach(box => {
                    map.set(box.BID, {
                        level: Level.BOX,
                        parentId: chamber.CID,
                        name: [building.BName, sector.SName, chamber.CName, box.BoxesName],
                        id: box.BID,
                        SType: sector.SType
                    })
                })
            })
        })
    });
    return map;
})



export const makeGetClosestPlacementByID = () => {
    return createSelector([getBuildingsMap, getClosestParams], (_buildingsMap, _params)=>{
        if(!_params.id || !_params.level) return null;
        const {id, level} = _params;
        const getPlacement = (plcmntId) => {
            const placement = _buildingsMap.get(plcmntId);
            if(!placement) return null;
            if(placement.level === level) return placement;
            else {
                if(plcmntId === placement.parentId) return null;
                return getPlacement(placement.parentId)
            }
        }
        return getPlacement(id);
    })
}

export const makeGetPlacementByID = () => {
    return createSelector([getBuildingsMap, getPlacementID], (_map, _id)=>{
        return _map.get(_id) || null
    })
}
