import {createSelector} from 'reselect';
import _ from "lodash";
import moment from "moment";


const getBuildings = (state, props) =>
    state.farms.buildings;

const getDevices = (state, props) =>
    state.farmDevices.devices;

const buildingsMapSelector = createSelector([getBuildings], items => {
    const map = new Map();
    items.forEach(building => {
        map.set(building.BgID, [building.BName]);
        (building.Sectors || []).forEach(sector => {
            map.set(sector.SID, [building.BName, sector.SName]);

            (sector.Chambers || []).forEach(chamber => {
                map.set(chamber.CID, [building.BName, sector.SName, chamber.CName]);
                (chamber.Boxes || []).forEach(box => {
                    map.set(box.BID, [building.BName, sector.SName, chamber.CName, box.BoxesName])
                })
            });
        });
    });
    return map;
});

const devicesMapSelector = createSelector([getDevices], items => {
    const map = new Map();
    items.forEach(device => {
        map.set(device.DevID, device);
    });
    return map;
});
const getAlarms = (state, props) =>
    state.iotAlarms.alarms;

const getFetching = (state, props) =>
    state.iotAlarms.fetching || {};

const getFetched = (state, props) =>
    state.iotAlarms.fetched || {};

export const getAlarmsData = createSelector(
    [getAlarms, devicesMapSelector, buildingsMapSelector],
    (_alarms, _devicesMap, _buildingsMap) => {
        const alarms = _alarms.slice(0);
        alarms.sort((o1, o2) => o2.newest.time - o1.newest.time);
        const getPlacementName = (plcmntID) => (_buildingsMap.get(plcmntID) || []);
        const getDate = (time) => time ? moment(time).format("DD.MM.YY HH:mm") : "-";
        const getTime = (time) => time ? moment(time).format("HH:mm") : "-";
        const getDevicePlacementName = (device, index = null) => {
            if (!device) {
                return [];
            }
            let plmnt = _.isArray(device.PlcmntID) ? device.PlcmntID : [{PlcmntID: device.PlcmntID}];
            return plmnt.filter(o => _.isNil(index) || (_.isFinite(o.Adr) && (_.isArray(index) ? index.includes(o.Adr) : index === o.Adr))).map(p => getPlacementName(p.PlcmntID));

        };
        const getAlarmText = (alarm = {}) => {
            const code = alarm.alarm.alert;
            const info = alarm.alarm.info || {};
            const key = `notificationsCenter.errorCodes.${code}`;
            let params = {};
            switch (code) {
                case 5108:
                case 5001:
                    params.number = info.RFID || "-";
                    params.location = getPlacementName(info.Plcmnt).join(" - ") || "?";
                    params.time = getDate(info.LastSeenTime)
                    break;

                case 5002:
                    params.number = info.RFID || "-";
                    params.expectedLoc = getPlacementName(info.expectedLocation).join(" - ") || "?";
                    params.currentLoc = getPlacementName(info.currentLocation).join(" - ") || "?";
                    break;

                case 5101:
                case 5102:
                case 5103:
                case 5104:
                case 5202:
                case 5204:
                    params.number = info.number || "-";
                    break;
                case 5107:
                    params.animal = info.RFID || "-";
                    params.loc = getPlacementName(info.PlcmntID).join(" - ") || "?";
                    break;
                case 1430:
                    params.time = getTime(info.Time);
                    break;
                case 5109:
                    params.location = getPlacementName(info.PlcmntID).join(" - ") || "?";
                    break;
                default:
                    break;
            }
            return {key, params};
        };
        const getAlarm = (alarm, device, index) => {
            const plcmt = getDevicePlacementName(device, index);
            return {
                title: device ? device.Name : "?",
                timestamp: alarm.time,
                text: getAlarmText(alarm),
                subtitle: plcmt
            }
        };
        return alarms.map(alarm => {
            const device = _devicesMap.get(alarm.DevID);
            const plcmt = getDevicePlacementName(device, alarm.Index);
            return {
                title: device ? device.Name : "?",
                timestamp: alarm.newest.time,
                text: getAlarmText(alarm.newest),
                subtitle: plcmt,
                _passThrough: {other: alarm.alarms.map(a => getAlarm(a, device, alarm.Index)), raw: alarm},
                _id: alarm._id,


            }
        });

    }
);

export const isFetching = createSelector(
    [getFetching],
    (_fetching) => {
        return Object.values(_fetching).reduce((a, b) => +a + +b, 0) !== 0;
    }
);

export const isFetched = createSelector(
    [getFetched],
    (_fetched) => {
        return !!Object.values(_fetched).length && (Object.values(_fetched).length === Object.values(_fetched).reduce((a, b) => +a + +b, 0));
    }
);

export default getAlarms;
