import {createSelector} from "reselect";
import {getSelectedObject, getShowAliases} from "./rowSelector";
import {getBuildingsMap} from "../buildingsSelector";
import {getDevices} from "../deviceSelector";
import {enhancedComparer} from "../../utils/TextUtils";
import Scale from "../../beans/devices/Scale";

export const getScaleData = createSelector([getShowAliases, getBuildingsMap, getDevices], (_showAlias, _buildingsMap, _devices) => {
    const siloData = [];
    _devices.forEach(device => {
        if (device instanceof Scale) {
            device.Siloses.forEach((silo, index) => {
                if (!!silo.Active) {
                    const dataRow = {
                        device: device,
                        placements: device.getPlacementArray(index).map(PlcmntID => _buildingsMap.get(PlcmntID)).filter(o => !!o)
                    }
                    dataRow.id = `${device.DevID}_${index}`;
                    dataRow.index = index;
                    dataRow.name = device.getAddressString();
                    if (_showAlias && device.Alias) dataRow.name = device.Alias;
                    if (dataRow.placements.length) dataRow.name = dataRow.placements.map(o => o.name.slice(0).pop()).join(", ");
                    siloData.push(dataRow);
                }
            })

        }
    })
    return siloData.sort((o1, o2) => enhancedComparer(o1.name, o2.name))
})


export const getSelected = createSelector([getScaleData, getSelectedObject], (_data, _selected) => {
    return _data.filter(o => o.device instanceof Scale && !!_selected[o.id]);
})
