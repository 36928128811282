import {createSelector} from "reselect";
import {get, isNil} from "lodash";
import {parseFarmMapFromSVG} from "../utils/FarmMapUtils";

const getMaps = (state, props) => state.settings.farmMap;

const getLevelNumber = (state, {selectedLevel}) => selectedLevel

const getCurrentFarm = (state, props) => state.location.farm;

export const getFarmMap = createSelector([getMaps, getCurrentFarm], (_farmMap, _currentFarmId) => {
    const currentMap = _farmMap.get(_currentFarmId);
    const levels = [];
    Object.entries((get(currentMap, "SetData.Levels") || {})).forEach(([name, mapString]) => {
        const parsed = parseFarmMapFromSVG(mapString);
        if (!isNil(parsed)) {
            levels.push({
                name: name,
                value: parsed
            })
        }
    })
    return levels.sort((o1, o2) => o1.name - o2.name);
})

export const getSelectedLevel = createSelector([getFarmMap, getLevelNumber], (_farmMap, _selectedLevel)=>{
    console.log("_selectedLevel", _selectedLevel, _farmMap)
    return _farmMap.find(o=> `${o.name}` === `${_selectedLevel}`) || null;
})
