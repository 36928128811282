import {createSelector} from "reselect";
import {get, isEmpty} from "lodash";
import * as RoleTypes from "validators-schema/Api/constants/roleTypes";
import {checkIfUserIsService} from "../utils/NewRolesUtils";

const getUser = (state) =>
    state.user.user;

const getFarms = (state) =>
    state.farms.farms;

const getSelectedUser = (state, {selectedUser}) =>
    selectedUser;

export const getFarm = (state, FarmID) => state.farms.farms.find(item => item.FarmID === FarmID);

export const getCurrentFarm = (state) => getFarm(state, state.location.farm);

export const getFarmIfUserHasUserManagementRole = createSelector(
    [getUser, getFarms],
    (user, farms) => {
        if (checkIfUserIsService()) {
            return farms.filter((f) => f.ClientID === user.ClientID);
        } else {
            const localRights = get(user, "Roles", []).find((role) => role.Role === RoleTypes.USER_MANAGE).LocalRights;
            if (!isEmpty(localRights)) {
                return farms.filter((f) => localRights.find((lr) => lr.FarmID === f.FarmID && lr.ClientID === user.ClientID));
            }
            return [];
        }
    }
);

export const getFarmsForChoosenUser = createSelector([getFarms, getSelectedUser], (farms, selectedUser = {}) => {
        // general read wszyscy musza miec ktorzy maja dostep na ferme
        const generalRead = get(selectedUser, "roles", []).find((role) => role.Role === RoleTypes.GENERAL_READ);
        if (!isEmpty(generalRead)) {
            return farms.filter((f) => get(generalRead, "LocalRights", []).find((lr) => lr.FarmID === f.FarmID));
        }
        return [];
    }
);
