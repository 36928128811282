import {createSelector} from 'reselect'
import {get, isArray, isNil, pick} from "lodash";
import {AnimalTypes} from "../constans/animalTypes";
import moment from "moment";
import {enhancedComparer} from "../utils/TextUtils";

export const InventorySortType = {
    BY_ANIMAL_NUMBER_ASC: 'SORT_BY_ANIMAL_NUMBER_ASC',
    BY_ANIMAL_NUMBER_DESC: 'SORT_BY_ANIMAL_NUMBER_DESC',
    BY_ANIMAL_TYPE_ASC: 'SORT_BY_ANIMAL_TYPE_ASC',
    BY_ANIMAL_TYPE_DESC: 'SORT_BY_ANIMAL_TYPE_DESC',
    BY_LOCATION_ASC: 'SORT_BY_LOCATION_ASC',
    BY_LOCATION_DESC: 'SORT_BY_LOCATION_DESC'
};

const getSort = (state) =>
    state.inventory.inventorySort;

const getShowCollapsed = (state) =>
    state.inventory.showCollapsed;

const getLocationFilter = (state) =>
    state.inventory.locationFilter;

const getStringFilter = (state, props) =>
    state.inventory.inventoryFilter;

const getAnimals = (state, props) =>
    state.inventory.animals;

const getGroups = (state, props) =>
    state.inventory.groups;

const getAnimalKinds = (state, props) =>
    state.inventory.animalTypeMap;

const getShowDead = (state, props) =>
    state.inventory.showDead;

const getBuildings = (state, props) =>
    state.farms.buildings;

const getFarms = (state) =>
    state.farms.farms;

const getTechnologyGroups = (state) =>
    state.inventory.technoGroups;


const getBuildingsMap = createSelector(
    [getBuildings, getFarms],
    (buildings = [], farms = []) => {
        const buildingsMap = new Map();
        farms.forEach(farm => {
            buildingsMap.set(farm.FarmID, {
                name: farm.FarmName,
                ids: [farm.FarmID]
            })
        });
        buildings.forEach(building => {
            const tmpBuilding = {
                name: building.BName,
                ids: [building.BgID]
            };
            get(building, "Sectors", []).forEach(sector => {
                tmpBuilding.ids = [...new Set([...tmpBuilding.ids, sector.SID])];
                const tmpSector = {
                    name: `${building.BName} - ${sector.SName}`,
                    ids: [sector.SID]
                };
                get(sector, "Chambers", []).forEach(chamber => {
                    tmpBuilding.ids = [...new Set([...tmpBuilding.ids, chamber.CID])];
                    tmpSector.ids = [...new Set([...tmpSector.ids, chamber.CID])];
                    const tmpChamber = {
                        name: `${building.BName} - ${sector.SName} - ${chamber.CName}`,
                        ids: [chamber.CID]
                    };
                    get(chamber, "Boxes", []).forEach(box => {
                        tmpBuilding.ids = [...new Set([...tmpBuilding.ids, box.BID])];
                        tmpSector.ids = [...new Set([...tmpSector.ids, box.BID])];
                        tmpChamber.ids = [...new Set([...tmpChamber.ids, box.BID])];
                        buildingsMap.set(box.BID, {
                            name: `${building.BName} - ${sector.SName} - ${chamber.CName} - ${box.BoxesName}`,
                            ids: [box.BID]
                        })
                    });
                    buildingsMap.set(chamber.CID, tmpChamber);
                });
                buildingsMap.set(sector.SID, tmpSector);

            });
            buildingsMap.set(building.BgID, tmpBuilding);
            const tmpFarm = buildingsMap.get(building.FarmID) || {
                name: get(farms.find(f => f.FarmID === building.FarmID), "FarmName", "?"),
                ids: [building.FarmID]
            };
            tmpFarm.ids = [...new Set([...tmpFarm.ids, ...tmpBuilding.ids])];
            buildingsMap.set(building.FarmID, tmpFarm);
        });
        return buildingsMap;

    }
);

const getInventory = createSelector(
    [getTechnologyGroups, getGroups, getAnimals, getSort, getStringFilter, getLocationFilter, getShowDead, getAnimalKinds, getBuildingsMap],
    (technoGroups, groups, animals, sortBy, stringFilter, locationFilter, showDead, animalTypeMap = new Map(), buildingsMap = new Map()) => {
        console.log(technoGroups, groups, animals, sortBy, stringFilter, locationFilter, showDead, animalTypeMap, buildingsMap)
        let inventory = [];
        const getLocationName = (item) => {
            return item.location.map(loc => get(buildingsMap.get(loc), "name", "?")).join(", ") || "-"
        };
        for (let animal of animals) {
            const tmp = {
                animal: animal,
                name: animal.AnmNo1 || "-",
                id: animal.AnmID,
                animalCount: Math.max(isFinite(+get(animal, "AnmCnt", 0)) ? +get(animal, "AnmCnt", 0) : 1, 1),
                typeName: animalTypeMap.get(animal.AnimalKind) || "-",
                location: isArray(animal.PlcmntID) ? animal.PlcmntID.map(o => o.PlcmntID) : [animal.PlcmntID]
            };
            tmp.search = JSON.stringify(pick(tmp, ["name", "animal.RFID", "typeName"]));
            tmp.locationName = getLocationName(tmp);
            inventory.push(tmp);
        }
        [...technoGroups, ...groups].forEach(group => {
            const animalsInGroup = [];
            const isTechnoGroup = !!group.TGID;
            group[isTechnoGroup ? "AnmList" : "AnmIDs"].forEach(anmId => {
                let animalIdx = inventory.findIndex(o => !o.isGroup && o.id === anmId);
                if (~animalIdx && !get(group, "Rmvd", []).includes(anmId)) {
                    animalsInGroup.push(inventory[animalIdx]);
                    inventory.splice(animalIdx, 1);
                }
            });

            const tmp = {
                group: group,
                isGroup: true,
                isTechnoGroup: isTechnoGroup,
                name: (isTechnoGroup ? moment(group.StartTime).format("DD.MM.YY") : group.GrNo1) || "-",
                id: isTechnoGroup ? group.TGID : group.AnmGrp,
                typeName: animalTypeMap.get(isTechnoGroup ? "techno" : "group"),
                animals: animalsInGroup,
                __StartTime: group.StartTime
            };
            inventory.push(tmp)

        });
        return inventory;
    }
);


export const getInventorySorted = createSelector(
    [getInventory, getShowCollapsed, getSort, getStringFilter, getLocationFilter, getShowDead, getAnimalKinds, getBuildingsMap],
    (_inventory, showCollapsed, sortBy, stringFilter, locationFilter, showDead, animalTypeMap = new Map(), buildingsMap = new Map()) => {
        const inventory = [];
        console.log(showCollapsed, showDead, "KDKDKDKD")
        let sortFunction = () => 0;
        const getTypeAsNumber = (item) => item.isTechnoGroup ? 3 : item.isGroup ? 1 : 0;
        const sortByType = (_o1, _o2, ascending = true) => {
            const o1 = ascending ? _o1 : _o2;
            const o2 = ascending ? _o2 : _o1;
            const diff = getTypeAsNumber(o1) - getTypeAsNumber(o2);
            if (diff) return diff;
            if (o1.isTechnoGroup && o2.isTechnoGroup) {
                return o1.__StartTime - o2.__StartTime;
            }
            return 0;
        };
        switch (sortBy) {
            case InventorySortType.BY_ANIMAL_NUMBER_DESC:
            case InventorySortType.BY_ANIMAL_NUMBER_ASC: {
                sortFunction = (o1, o2) => sortByType(o1, o2, sortBy.endsWith("_ASC")) || enhancedComparer(get(o1, "name"), get(o2, "name"), {
                    numeric: true,
                    ascending: sortBy.endsWith("_ASC")
                });
                break;
            }
            case InventorySortType.BY_ANIMAL_TYPE_DESC:
            case InventorySortType.BY_ANIMAL_TYPE_ASC: {
                sortFunction = (o1, o2) => sortByType(o1, o2) || enhancedComparer(get(o1, "typeName"), get(o2, "typeName"), {
                    numeric: true,
                    ascending: sortBy.endsWith("_ASC")
                });
                break;
            }
            case InventorySortType.BY_LOCATION_DESC:
            case InventorySortType.BY_LOCATION_ASC: {
                sortFunction = (o1, o2) => sortByType(o1, o2) || enhancedComparer(get(o1, "locationName"), get(o2, "locationName"), {
                    numeric: true,
                    ascending: sortBy.endsWith("_ASC")
                });
                break;
            }
            default:
                break;
        }
        const getLocationName = (item) => {
            return item.location.map(loc => get(buildingsMap.get(loc), "name", "?")).join(", ") || "-"
        };
        let showingGroups = false;
        let showingTechno = false;
        const newStringFilter = (stringFilter || []).filter(word => {
            let show = true;
            if (word.length > 3) {
                if (animalTypeMap.get('group').includes(word)) {
                    showingGroups = true;
                    show = false;
                }
                if (animalTypeMap.get('techno').includes(word)) {
                    showingTechno = true;
                    showingGroups = animalTypeMap.get('group').includes(word);
                    show = false;
                }
            }
            return show;
        });
        let showGroupsOnly = showingGroups || showingTechno;
        const performFilter = (item) => {
            let show = true;
            if (newStringFilter && item.isGroup ? true : !showGroupsOnly) {
                for (let filter of newStringFilter) {
                    if (!item.search.toLowerCase().includes(filter.toLowerCase())) {
                        show = false;
                        break;
                    }
                }
            }
            if (locationFilter && !item.location.filter(loc => get(buildingsMap.get(locationFilter), "ids", [locationFilter]).includes(loc)).length) {
                show = false;
            }
            return show ? item : null;
        };

        for (let o of _inventory) {
            let tmp = {...o};
            if (o.isGroup) {
                let location = [];
                const search = [];
                const animals = [];
                if (!showCollapsed) {
                    o.animals.forEach(animal => {
                        if ((showDead || isNil(animal.animal.DtaDthTime)) && (performFilter(animal))) {
                            animals.push(animal);
                            search.push(animal.search);
                            location = [...new Set([...location, ...animal.location])];
                        }
                    });
                }
                tmp.search = JSON.stringify(search);
                tmp.search = JSON.stringify(pick(tmp, showGroupsOnly ? ["name", "typeName"] : ["name", "search", "typeName"]));
                tmp.animals = animals.sort(sortFunction);
                tmp.location = location;
                tmp.locationName = getLocationName(tmp);
                tmp = performFilter(tmp);
                if ((!showGroupsOnly || (o.isGroup && showingGroups) || (o.isTechnoGroup && showingTechno)) && tmp) {
                    inventory.push(tmp);
                }
            } else if (!showGroupsOnly && (showDead || isNil(tmp.animal.DtaDthTime))) {
                tmp = performFilter(tmp);
                if (tmp) {
                    inventory.push(tmp);
                }
            }

        }

        return inventory.sort(sortFunction).slice(0);
    }
);

export const getTotalAnimals = createSelector(
    [getAnimals, getInventorySorted], (animals, selected) => {
        const results = {};
        Object.values(AnimalTypes).forEach(value => {
            results[value] = {
                selected: 0,
                total: 0
            }
        });
        const addAnimalCount = (animal, key) => {
            if (key === "selected" || !animal.DtaDthTime) {
                const animalCount = Math.max(isFinite(+get(animal, "AnmCnt", 0)) ? +get(animal, "AnmCnt", 0) : 1, 1);
                results[animal.AnimalKind] && (results[animal.AnimalKind][key] += animalCount);
            }
        };
        animals.forEach(animal => {
            if (!animal.DtaDthTime) {
                addAnimalCount(animal, "total");
            }
        });
        selected.forEach(item => {
            if (item.isGroup) {
                item.animals.forEach(animal => {
                    addAnimalCount(animal, "selected");
                });
            } else {
                addAnimalCount(item.animal, "selected");
            }
        });
        return results;
    }
);


export default getInventorySorted;
