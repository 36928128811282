import {createSelector} from "reselect";
import {get} from "lodash";

const defaultEmptyObject = {};

export const getSelectedID = (state, {id}) => id;


export const getSelectedObject = (state, {type}) => state.mainTab.selected[type] || defaultEmptyObject;

export const getExpandedObject = (state, {type}) => state.mainTab.expanded[type] || defaultEmptyObject;

export const makeIsSelected = () => {
    return createSelector([getSelectedID, getSelectedObject], (_id, _selected) => {
        return !!_selected[_id];
    })
}

export const makeIsExpanded = () => {
    return createSelector([getSelectedID, getExpandedObject], (_id, _expanded) => {
        return !!_expanded[_id];
    })
}

export const getShowAliases = (state) => !!get(state, "general.SetData.Settings.Devices.ShowAliases", false);

