import {createSelector} from 'reselect'
import {get, isArray, isObject} from "lodash";
import animalsDB from "../database/animalsDB";
import {LogTableTypes} from "../constans/logCodeTypes";
import moment from "moment";
import {compareTwoStrings} from "string-similarity";
import {DICTIONARY_TYPE} from "../constans/general";
import {DevType} from "../constans/devices";
import {LogParser} from "../beans/LogParser";
import i18next from "i18next";
import {controlLists} from "../constans/controlLists";
import {getControlListName} from "../utils/ControlListsUtils";
import {getTranslationPath} from "../utils/EventUtils";

const getBuildings = (state) =>
    state.farms.buildings

const getFarms = (state) =>
    state.farms.farms

const getAnimalsTime = (state) =>
    state.animals.time

const getFarmID = (state) =>
    state.location.farm

const getSearchString = (state) =>
    state.logs.search

const getStartFrom = (state) =>
    state.logs.startFrom

const getEmployees = (state) =>
    state.user.allUsers

const getGroups = (state) =>
    state.groups.groups

const getSettings = (state) =>
    state.settings.settings

const getObjectId = (state) =>
    state.logs.objectId
//selectory dzialaja na zmemoizowanych argumentach jesli zmieni sie time to mozna zalozyc ze w animalsDB sa najnowsze zwierzeta

const getAnimalDictionary = createSelector([getAnimalsTime, getFarmID], (_time, _farmID) => {
    const dict = {};
    const _animals = animalsDB.getAllAnimals(_farmID, undefined, true, false);
    _animals.forEach(animal => {
        dict[animal.AnmID] = animal.AnmNo1;
    })

    return dict;
})

const getSettingDictionary = createSelector([getSettings], (_settings) => {
    const dict = {};
    _settings.forEach(setting => {
        //todo: dodac nazwy jakis glownych settingsow bez nazwy (w sensie bez SetData.Name)
        dict[setting.SetID] = get(setting, "SetData.Name", setting.SetID);
    })
    dict.General = i18next.t("newSettings.users.changeRolesView.general"); //głowne maja taki klucz
    return dict;
})

const getPlacementDictionary = createSelector([getBuildings, getFarms], (_buildings, _farms) => {
    const dict = {};
    //farmId -> farmName
    _farms.forEach(farm => {
        dict[farm.FarmID] = farm.FarmName;
    })
    //buildings
    _buildings.forEach(building => {
        dict[building.BgID] = building.BName;
        get(building, "Sectors", []).forEach(sector => {
            dict[sector.SID] = sector.SName;
            get(sector, "Chambers", []).forEach(chamber => {
                dict[chamber.CID] = chamber.CName;
                get(chamber, "Boxes", []).forEach(box => {
                    dict[box.BID] = `${chamber.CName} - ${box.BoxesName}`;
                })
            })
        })
    })
    return dict;
})

const getBuildingDictionary = createSelector([getBuildings], (_buildings) => {
    const dict = {};
    _buildings.forEach(building => {
        dict[building.BgID] = building.BName;
    })
    return dict;
})
const getDevices = (state) =>
    state.farmDevices.devices

export const getDeviceDictionary = createSelector([getDevices], (_devices) => {
    const dict = {};
    _devices.forEach(device => {
        dict[device.DevID] = device.Name;
        if ([DevType.SCALE, DevType.DISPENSER].includes(device.DevType)) {
            for (let i = 0; i < 20; i++) {
                dict[`${device.DevID}_${i}`] = `${device.Name} {${i}}`;
            }
        }
    })
    return dict;
})

export const getGroupsDictionary = createSelector([getGroups], (_groups) => {
    const dict = {
        AnmID: {},
        GrID: {}
    };
    _groups.sort((o1, o2) => o1.DtaModTime - o2.DtaModTime);
    _groups.forEach(group => {
        dict.GrID[group.GrID] = group.GrNo1;
        group.AnmIDs.forEach(AnmID => {
            if (!dict.AnmID[AnmID]) {
                dict.AnmID[AnmID] = group.GrNo1;
            }
        })
    })
    return dict;
})

export const getEmployeeDictionary = createSelector([getEmployees], (_employees) => {
    const dict = {};
    _employees.forEach(emp => {
        dict[emp.LocalUserID] = emp.Name;
    })
    return dict;
})

const getLogs = (state) =>
    state.logs.logs

const getUserID = (state) =>
    state.logs.userId

const getCodes = (state) =>
    state.logs.searchCode || [];

const getCodeParams = createSelector([getSearchString, getCodes], (_search, _codes) => {
    const result = {
        codes: [],
        selectedCodes: []
    }
    const search = _search.trim().toLowerCase();
    const codes = i18next.t([`logsView.shortNames`, "{}"], {returnObjects: true})
    console.log(codes, "codes")
    _codes.forEach(code => {
        result.selectedCodes.push({
            type: "codes",
            value: +`${code}`.split("_")[0],
            name: `${codes[code]}`
        })
    })
    if (search) {
        //przepiać na inny klucz
        for (let [code, name] of Object.entries(codes)) {
            if (`${name}`.toLowerCase().includes(search)) {
                result.codes.push({
                    type: "codes",
                    value: +`${code}`.split("_")[0],
                    name: `${name}`
                })
            }
        }
        console.log(Object.entries(codes), "cdParams");
    }
    return result;
})

const getEmployeeParams = createSelector([getSearchString, getUserID, getEmployeeDictionary], (_search, _userId, _employees) => {
    const result = {
        users: [],
        selectedUser: null
    }
    const search = _search.trim().toLowerCase();
    if (_userId && _employees[_userId]) {
        result.selectedUser = {
            type: "user",
            value: _userId,
            name: _employees[_userId]
        }
    }
    if (search) {
        for (let [id, name] of Object.entries(_employees)) {
            if (`${name}`.toLowerCase().includes(search)) {
                result.users.push({
                    type: "user",
                    value: id,
                    name: name
                })
            }
        }
    }
    console.log("KDKDKDxD", _employees, result)
    result.users.sort((o1, o2) => compareTwoStrings(o2.name, search) - compareTwoStrings(o1.name, search))
    return result;
})


const getDateParams = createSelector([getSearchString, getStartFrom], (_search, _startFrom) => {
    const search = _search.trim().toLowerCase();
    const result = {
        selectedDate: null,
        dates: []
    }
    if (search) {
        const dateFormats = ["DD.MM.YY", "DD.MM", "DD"];
        for (let format of dateFormats) {
            const tmp = moment.utc(search, format).endOf("day");
            if (tmp.isValid()) {
                result.dates.push({
                    type: "date",
                    name: tmp.format("DD.MM.YY"),
                    value: +tmp
                });
                break;
            }
        }
    }
    if (_startFrom) {
        const tmp = moment.utc(_startFrom).endOf("day");
        result.selectedDate = {
            type: "date",
            name: tmp.format("DD.MM.YY"),
            value: +tmp
        };
    }
    return result;
})


const getDictionaries = (state) => state.dictionary

const getLang = (state) => state.language.lang.lang

export const getDictionaryDictionary = createSelector([getDictionaries, getLang], (_dictionaries, _lang) => {
    const result = {};
    const defaultLang = "en";
    result.NAMES = {
        MEDICINE: {},
        CONTROLLIST: {},
        DICTIONARY: {},
        INGREDIENT: {},
        GRAFTINGPROGRAM: {}
    };
    Object.values(DICTIONARY_TYPE).forEach(dictKey => {
        result[dictKey] = {};
        const specialKeys = [DICTIONARY_TYPE.medicine, DICTIONARY_TYPE.controlLists, DICTIONARY_TYPE.forageIngredient, DICTIONARY_TYPE.graftingProgram];
        const specialPaths = ["medicine", "controlLists", "forageIngredients", "graftingPrograms"];
        const key = specialPaths[specialKeys.findIndex(o => o === dictKey)] || "someNonExistingKey";
        switch (key) {
            case "graftingPrograms": {
                const arr = get(_dictionaries, key, []);
                arr.forEach(item => {
                    result[dictKey][item.WordID] = item.WData.Name;
                    result.NAMES.GRAFTINGPROGRAM[item.WordID] = item.WData.Name;
                })
                break;
            }

            case "forageIngredients": {
                const arr = get(_dictionaries, key, []);
                arr.forEach(item => {
                    result[dictKey][item.WordID] = item.WData.Name;
                    result.NAMES.INGREDIENT[item.WordID] = item.WData.Name;
                })
                break;
            }
            case "medicine": {
                const arr = get(_dictionaries, key, []);
                arr.forEach(item => {
                    result[dictKey][item.WordID] = item.WData.Name;
                    result.NAMES.MEDICINE[item.WordID] = item.WData.Name;
                })
                break;
            }
            case "controlLists": {
                Object.values(controlLists).forEach(clKey => {
                    const name = getControlListName(clKey);
                    result[dictKey][clKey] = name;
                    result.NAMES.CONTROLLIST[clKey] = name;
                })
                break;
            }
            default: {
                for (let dict of Object.values(_dictionaries)) {
                    if (isObject(dict) && !isArray(dictKey)) {
                        if (dict.Type === dictKey) {
                            const arr = get(dict, `WData[${_lang}]`, get(dict, `WData[${defaultLang}]`, []));
                            arr.forEach(item => {
                                result[dictKey][item.ID] = item.Value;
                            })
                            const translationPath = getTranslationPath(dict.Type);
                            if (translationPath) {
                                result.NAMES.DICTIONARY[dict.WordID] = i18next.t(translationPath);
                            }
                        }
                    }
                }
                break;
            }

        }
    })
    return result;
})


const getObjectParams = createSelector([getSearchString, getObjectId, getDeviceDictionary, getAnimalDictionary, getDictionaryDictionary, getSettingDictionary, getBuildingDictionary], (_search, _objectId, _devices, _animals, _dictionaries, _settings, _buildings) => {
    const result = {
        selectedObject: null,
        objects: []
    }
    const types = [LogTableTypes.DEVICES, LogTableTypes.ANIMALS, LogTableTypes.DICTIONARY, "Medicine", "ControlList", "Ingredient", "GraftingProgram", LogTableTypes.SETTINGS, LogTableTypes.BUILDINGS];
    const search = _search.trim().toLowerCase();
    console.log(_dictionaries.NAMES, "NAMESSS");

    [_devices, _animals, _dictionaries.NAMES.DICTIONARY, _dictionaries.NAMES.MEDICINE, _dictionaries.NAMES.CONTROLLIST, _dictionaries.NAMES.INGREDIENT, _dictionaries.NAMES.GRAFTINGPROGRAM, _settings, _buildings].forEach((map, index) => {
        console.log("debug bob", map);
        Object.entries(map).forEach(([id, name = ""]) => {
            if (id === _objectId) {
                result.selectedObject = {
                    type: types[index],
                    name: name,
                    value: id
                }
            }
            if (search && `${name}`.toLowerCase().includes(search)) {
                result.objects.push({
                    type: types[index],
                    name: name,
                    value: id
                })
            }
        })

    })
    result.objects.sort((o1, o2) => compareTwoStrings(o2.name, search) - compareTwoStrings(o1.name, search))
    return result;
})


export const getSearchParams = createSelector([getObjectParams, getEmployeeParams, getDateParams, getCodeParams], (_objectParams, _userParams, _dateParams, _codeParams) => {
    return {..._objectParams, ..._userParams, ..._dateParams, ..._codeParams};
})
export const getKeyValueLogDict = createSelector(
    [getDictionaryDictionary, getDeviceDictionary, getPlacementDictionary, getAnimalDictionary, getEmployeeDictionary, getGroupsDictionary, getSettingDictionary, getBuildingDictionary], (_dictionaries, _devices, _placements, _animals, _employees, _groups, _settings, _buildings) => ({
        devices: _devices,
        placements: _placements,
        buildings: _buildings,
        animals: _animals,
        employees: _employees,
        dictionaries: _dictionaries,
        groups: _groups,
        settings: _settings
    })
)
export const getParsedLogs = createSelector(
    [getLogs, getUserID, getObjectId, getStartFrom, getSearchString, getKeyValueLogDict],
    (_logs, _userId, _objectId, _startFrom, _search, _dictionaries) => {
        const objectIdCondition = (log) => _objectId ? log.ObjID === _objectId : true;
        const startFromCondition = (log) => _startFrom ? log.DtaCrtTime <= _startFrom : true;
        const userIdCondition = (log) => _userId ? log.UserID === _userId : true;
        return _logs.filter(log => objectIdCondition(log) && startFromCondition(log) && userIdCondition(log)).map(log => ({
            log: log, ...LogParser.createLogParser(log, _dictionaries).getData()
        }))
    }
);


