import {createSelector} from "reselect";
import {NotificationStatus} from "../constans/notifications";

const getNotifications = (state) => state.notificationCenter.notifications;

const getLastRead = (state) => state.notificationCenter.readTime;


export const getBadgeColour = createSelector([getNotifications, getLastRead], (_notifications, _readTime) => {
    const type = [NotificationStatus.ALERT, NotificationStatus.WARN, NotificationStatus.INFO];
    const colors = ["alarm", "warn", "info"];
    let value = 4;
    for (let notification of _notifications) {
        if (notification.DtaCrtTime >= _readTime) {
            const tmp = type.findIndex(o => o === notification.NData.Status);
            if (tmp !== -1 && (tmp < value)) {
                value = tmp;
            }
            if (!value) return colors[value]; //jesli jest najwyzszy to wychodzimy z fora bo bez sensu marnowac ostatki wydajnosci na froncie xd
        }
    }
    return colors[value] || "";
})

export const getUnreadCounter = createSelector([getNotifications, getLastRead], (_notifications, _readTime) => {
    return _notifications.filter(o => o.DtaCrtTime >= _readTime).length
})
