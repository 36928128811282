import {createSelector} from 'reselect'
import {get} from "lodash"
import {LicPackageKeys, LicPackageLevel, Roles} from "../constans/roles";

const getUser = (state) =>
    state.user;

const getFarm = (state) =>
    state.location.farm;

const getSpecificRole = (state, props) =>
    props.licenseName;

export const getLicensePackage = createSelector(
    [getUser, getFarm],
    (user, farm) => {
        const basic = (name) => `${name}Basic`;
        const extended = (name) => `${name}Ext`;
        const f2bRepresentation = [
            [LicPackageKeys.ADMINISTRATION, "adm"],
            [LicPackageKeys.ALARM, "al"],
            [LicPackageKeys.CAGE, "cg"],
            [LicPackageKeys.CHAIN, "ch"],
            [LicPackageKeys.CLIMATE, "cl"],
            [LicPackageKeys.COUNTER, "cnt"],
            [LicPackageKeys.DISPENSER_5G, "di5G"],
            [LicPackageKeys.DISPENSER, "di"],
            [LicPackageKeys.PORKER, "pork"],
            [LicPackageKeys.SMALL_CAGE, "sc"],
            [LicPackageKeys.SILO, "sl"],
            [LicPackageKeys.SOW, "sow"],
        ];
        const result = {};
        let isService = get(user, "user.Roles", []).find(r => r.Role === Roles._SERVICE);
        for (let [key, name] of f2bRepresentation) {
            result[key] = LicPackageLevel.NO_ACCESS;
            if (isService) {
                result[key] = LicPackageLevel.EXTENDED;
                continue;
            }
            if (!!get(user, `user.Packages[${farm}].${basic(name)}`)) {
                result[key] = LicPackageLevel.BASIC;
                if (!!get(user, `user.Packages[${farm}].${extended(name)}`)) {
                    result[key] = LicPackageLevel.EXTENDED;
                }
            }
        }
        return result;
    }
);

export const getLicenseByName = createSelector([getLicensePackage, getSpecificRole], (licenses, name) => {
    return licenses[name] || LicPackageLevel.NO_ACCESS;
});
