import {createSelector} from "reselect";

export const getTranslationSelector = (state, locale) => state.language.translations.content[locale];

export function flatObject(object, path = "") {
    let array = []
    for (let key in object) {
        let p = path ? path + "." + key : key;
        let value = object[key];
        if (typeof value === "string") {
            array.push({
                value: value.toLowerCase(),
                path: p
            });
        } else {
            if (!Array.isArray(value)) {
                array.push(...flatObject(value, p));
            } else {
                for (let i = 0; i < value.length; i++) {
                    let arrayValue = value[i];
                    if (typeof arrayValue === "string") {
                        array.push({
                            value: arrayValue.toLowerCase(),
                            path: p + `[${i}]`
                        });
                    } else {
                        array.push(...flatObject(arrayValue, p + `[${i}]`));
                    }
                }
            }
        }
    }
    return array;
}

export const validateTranslationSelector = createSelector(
    getTranslationSelector,
    (translation) => flatObject(translation)
)