import {aggregatedDataID} from "../utils/DevicesUtils";
import {createSelector} from "reselect";

const defaultEmptyArray = [];

export const getAggData = (state, {DevID, index=null}) =>
    state.aggregatedData.data.get(aggregatedDataID({DevID, index})) || defaultEmptyArray;

export const getFetching = (state, {DevID, index = null}) =>
    state.aggregatedData.fetchingMap.get(aggregatedDataID({DevID, index}))

export const makeGetAggregatedData = () => {
    return createSelector(
        [getAggData, getFetching],
        (_data, _fetching) => {
            return {
                data: _data,
                fetching: !!_fetching
            }
        });
}
