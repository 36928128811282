import {createSelector} from 'reselect'
import {get, isNil, pick} from "lodash";
import {enhancedComparer} from "../utils/TextUtils";
import {CurveDayShowingType, CurveType} from "../constans/feedingTypes";
import {getCurveDayShowingType} from "../utils/FeedingUtils";
import {getLicensePackage} from "./roleSelector";
import {LicPackageKeys, LicPackageLevel} from "../constans/roles";
import {LicenseItemCounts} from "../constans/licenseCounts";


export const FeedingSortType = {
    BY_ANIMAL_NUMBER_ASC: 'SORT_BY_ANIMAL_NUMBER_ASC',
    BY_ANIMAL_NUMBER_DESC: 'SORT_BY_ANIMAL_NUMBER_DESC',
    BY_USAGE_ASC: 'SORT_BY_USAGE_ASC',
    BY_USAGE_DESC: 'SORT_BY_USAGE_DESC',
    BY_ACTIVITY_ASC: 'SORT_BY_ACTIVITY_ASC',
    BY_ACTIVITY_DESC: 'SORT_BY_ACTIVITY_DESC',
    BY_DEFAULT_ASC: 'SORT_BY_DEFAULT_ASC',
    BY_DEFAULT_DESC: 'SORT_BY_DEFAULT_DESC',
    BY_PERCENTAGE_ASC: 'SORT_BY_PERCENTAGE_ASC',
    BY_PERCENTAGE_DESC: 'SORT_BY_PERCENTAGE_DESC'
};

// const getId = (state, props) =>
//     props.id;

export const getSort = (state, {CID}) =>
    CID && state.feeding.feeding[CID] ? state.feeding.feeding[CID].feedingSort : FeedingSortType.BY_NAME_ASC;

const getChamber = (state, {chamber}) =>
    chamber;

export const getFilter = (state, {CID}) =>
    CID && state.feeding.feeding[CID] ? state.feeding.feeding[CID].feedingFilter : "";

const getItems = (state, {CID}) =>
    CID && state.feeding.feeding[CID] ? Object.values(state.feeding.feeding[CID].data) : [];

export const isSelected = (f = {}) => !!f.feed && !!f.selected;


const getDevicesRFID = (state, {CID}) =>
    CID && state.feeding.feeding[CID] ? Object.values(state.feeding.feeding[CID].devices) : [];


const pickKeys = (obj) => pick(obj, ["animal.AnmNo1", "box.BoxesName", "animal.RFID"]);

export const getFeedingByBoxes = createSelector([getItems, getChamber], (feeding, chamber = {}) => {
    const {IndividualFeeding, StandsInRow, StandsOrder} = chamber;
    console.log(feeding, chamber, "DKDSOAKSODAS")
    if (IndividualFeeding) {
        const sortByStandNumber = (o1, o2, ascending = true) =>
            enhancedComparer(get(ascending ? o1 : o2, "name"), get(ascending ? o2 : o1, "name"), {numeric: true});
        let dataSorted = feeding;
        const globalSortAscending = !((StandsOrder >> 1) & 0b01);
        const nextRowSwapSort = !!((StandsOrder >> 2) & 0b01);
        const localRowAscending = !!((StandsOrder) & 0b01);
        console.log(globalSortAscending, nextRowSwapSort, localRowAscending, "sorting options");
        dataSorted.sort((o1, o2) => sortByStandNumber(o1, o2, globalSortAscending));
        let lastIndex = 0;
        const data = [];
        while (lastIndex < dataSorted.length) {
            const startIndex = lastIndex;
            const endIndex = Math.min(lastIndex + StandsInRow, dataSorted.length);
            lastIndex = endIndex;
            const tmp = dataSorted.slice(startIndex, endIndex);
            tmp.sort(
                (o1, o2) => sortByStandNumber(o1, o2, (nextRowSwapSort && (data.length % 2 === 1)) ? !localRowAscending : localRowAscending)
            );
            data.push(tmp);
        }
        return data;
    } else {
        return [];
    }
});

export const getFeedingSorted = createSelector(
    [getSort, getItems, getFilter],
    (feedingSort, feeding, filter) => {
        let sortFunc;
        switch (feedingSort) {
            case FeedingSortType.BY_ANIMAL_NUMBER_DESC:
            case FeedingSortType.BY_ANIMAL_NUMBER_ASC:
                sortFunc = (f1, f2) =>
                    enhancedComparer(get(f1, "animal.AnmNo1"), get(f2, "animal.AnmNo1"), {
                        numeric: true,
                        ascending: feedingSort.endsWith("_ASC")
                    });
                break;
            case FeedingSortType.BY_USAGE_DESC:
            case FeedingSortType.BY_USAGE_ASC:
                sortFunc = (f1, f2) =>
                    enhancedComparer(get(f1, "feed.usage"), get(f2, "feed.usage"), {
                        numeric: true,
                        ascending: feedingSort.endsWith("_ASC")
                    });
                break;
            case FeedingSortType.BY_ACTIVITY_DESC:
            case FeedingSortType.BY_ACTIVITY_ASC:
                sortFunc = (f1, f2) =>
                    enhancedComparer(get(f1, "lastSeen.time"), get(f2, "lastSeen.time"), {
                        numeric: true,
                        ascending: !feedingSort.endsWith("_ASC")
                    });
                break;
            case FeedingSortType.BY_DEFAULT_DESC:
            case FeedingSortType.BY_DEFAULT_ASC:
                sortFunc = (f1, f2) =>
                    enhancedComparer(get(f1, "name"), get(f2, "name"), {
                        numeric: true,
                        ascending: feedingSort.endsWith("_ASC")
                    });
                break;
            case FeedingSortType.BY_PERCENTAGE_DESC:
            case FeedingSortType.BY_PERCENTAGE_ASC:
                sortFunc = (f1, f2) =>
                    enhancedComparer(
                        isNil(get(f1, "feed.usage")) ? null : (get(f1, "feed.usage", 0) / (get(f1, "feed.plannedUsage", 0) || 3000))
                        , isNil(get(f2, "feed.usage")) ? null : (get(f2, "feed.usage", 0) / (get(f2, "feed.plannedUsage", 0) || 3000))
                        , {numeric: true, ascending: feedingSort.endsWith("_ASC")});
                break;
            default:
                sortFunc = (f1, f2) => 0;
                break;
        }
        return (filter ? feeding.slice(0).filter(f => JSON.stringify(pickKeys(f)).toLowerCase().includes(filter.toLowerCase())) : feeding.slice(0)).sort(sortFunc);
    }
);

export const getFeedingSelected = createSelector(
    [getItems],
    (feeding) => {
        return feeding.slice(0).filter(f => isSelected(f));
    }
);

export const getFeedingRFIDDevices = createSelector([getDevicesRFID], (devices) => {
    return devices.sort((o1, o2) => o1.device.Address - o2.device.Address);
});

const getOnReportAction = (state) =>
    state.settings && state.settings.general ? state.settings.general : {};


const getCurves = (state) =>
    state.settings.feedingCurves;

const getSchedule = (state) =>
    state.settings.feedingSchedules;

const getForages = (state) =>
    state.settings.forage;

export const getFeedingCurves = createSelector([getCurves, getLicensePackage], (curves, license) => {
    switch (license[LicPackageKeys.DISPENSER]) {
        case LicPackageLevel.EXTENDED:
            return curves.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_CURVE_EXTENDED) && (c.SetData.Index >= 0));
        case LicPackageLevel.BASIC:
            return curves.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_CURVE_BASIC) && (c.SetData.Index >= 0));
        case LicPackageLevel.NO_ACCESS:
        default:
            return [];
    }
});

export const getFeedingSchedules = createSelector([getSchedule, getLicensePackage], (schedules, license) => {
    switch (license[LicPackageKeys.DISPENSER]) {
        case LicPackageLevel.EXTENDED:
            return schedules.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_SCHEDULE_EXTENDED) && (c.SetData.Index >= 0));
        case LicPackageLevel.BASIC:
            return schedules.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_SCHEDULE_BASIC) && (c.SetData.Index >= 0));
        case LicPackageLevel.NO_ACCESS:
        default:
            return [];
    }
});

export const getFeedingForages = createSelector([getForages, getLicensePackage], (forages, license) => {
    switch (license[LicPackageKeys.DISPENSER]) {
        case LicPackageLevel.EXTENDED:
            return forages.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_FORAGE_EXTENDED) && (c.SetData.Index >= 0));
        case LicPackageLevel.BASIC:
            return forages.filter(c => (c.SetData.Index < LicenseItemCounts.FEEDING_FORAGE_BASIC) && (c.SetData.Index >= 0));
        case LicPackageLevel.NO_ACCESS:
        default:
            return [];
    }
});

export const onReportParturitionData = createSelector([getFeedingCurves, getOnReportAction], (curves, general) => {
    const {DefaultParturitionCurveDay: DefaultParturitionCurveDayWST = 1, DefaultParturitionCurveNumber: DefaultParturitionCurveNumberWST = 0} = get(general, "SetData.Settings.Feeding.WST", {});
    const {DefaultParturitionCurveDay: DefaultParturitionCurveDayNRF = 1, DefaultParturitionCurveNumber: DefaultParturitionCurveNumberNRF = 0} = get(general, "SetData.Settings.Feeding.NRF", {});
    //wst moze byc tylko indv albo ins bez laczenia
    const curveWST = curves.find(c =>
        [CurveType.PARTURITION, CurveType.INDIVIDUAL].includes(c.SetData.Type)
        && (c.SetData.Index === (DefaultParturitionCurveNumberWST - 1))
        && (getCurveDayShowingType(c) !== CurveDayShowingType.BOTH)
    );
    const dayWST = Math.min(get(curveWST, "SetData.Days.lenth", 1), DefaultParturitionCurveDayWST);
    //nrf moze byc laczona ale wtedy ustawiany dzien to bedzie dzien inseminacji
    const curveNRF = curves.find(c =>
        [CurveType.PARTURITION, CurveType.INDIVIDUAL].includes(c.SetData.Type)
        && (c.SetData.Index === (DefaultParturitionCurveNumberNRF - 1))
    );
    const dayNRF =
        curveNRF ?
            Math.min((getCurveDayShowingType(curveNRF) === CurveDayShowingType.BOTH ?
                get(curveNRF, "SetData.InseminationJumpTo", 1) : DefaultParturitionCurveDayNRF),
                get(curveNRF, "SetData.Days.length", 1))
            : 1;
    return {
        WST: {
            curve: curveWST,
            day: dayWST
        },
        NRF: {
            curve: curveNRF,
            day: dayNRF
        }
    };
});


export const onReportInseminationData = createSelector([getFeedingCurves, getOnReportAction], (curves, general) => {
    const {DefaultInseminationCurveDay: DefaultInseminationCurveDayWST = 1, DefaultInseminationCurveNumber: DefaultInseminationCurveNumberWST = 0} = get(general, "SetData.Settings.Feeding.WST", {});
    const {DefaultInseminationCurveDay: DefaultInseminationCurveDayNRF = 1, DefaultInseminationCurveNumber: DefaultInseminationCurveNumberNRF = 0} = get(general, "SetData.Settings.Feeding.NRF", {});
    //wst moze byc tylko indv albo ins bez laczenia
    const curveWST = curves.find(c =>
        [CurveType.MATING, CurveType.INDIVIDUAL].includes(c.SetData.Type)
        && (c.SetData.Index === (DefaultInseminationCurveNumberWST - 1))
        && (getCurveDayShowingType(c) !== CurveDayShowingType.BOTH)
    );
    const dayWST = Math.min(get(curveWST, "SetData.Days.lenth", 1), DefaultInseminationCurveDayWST);
    //nrf moze byc laczona ale wtedy ustawiany dzien to bedzie dzien inseminacji
    const curveNRF = curves.find(c =>
        [CurveType.MATING, CurveType.INDIVIDUAL].includes(c.SetData.Type)
        && (c.SetData.Index === (DefaultInseminationCurveNumberNRF - 1))
    );
    const dayNRF =
        curveNRF ?
            Math.min((getCurveDayShowingType(curveNRF) === CurveDayShowingType.BOTH ?
                get(curveNRF, "SetData.InseminationJumpTo", 1) : DefaultInseminationCurveDayNRF),
                get(curveNRF, "SetData.Days.length", 1))
            : 1;
    return {
        WST: {
            curve: curveWST,
            day: dayWST
        },
        NRF: {
            curve: curveNRF,
            day: dayNRF
        }
    };
});

export default getFeedingSorted;
