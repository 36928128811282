import {createSelector} from "reselect";
import {get} from "lodash";

const getSubscriptions = (state) =>
    state.braintree.subscriptionList;

const getFarmData = (state) =>
    state.user.user.FarmData;

const getFarmID = (state, {farmID}) =>
    farmID;

const getSelectedFarm = (state, {selectedFarm}) =>
    selectedFarm;

export const getSubscriptionBasedOnFarmID = createSelector(
    [getSubscriptions, getFarmData, getFarmID],
    (subscriptions, farmData, farmID = "") => {
        const farm = farmData.find((l) => l.FarmID === farmID);
        const subscription = subscriptions.find((s) => s.id === farm.SubID);
        return {
            subscription,
            addOns: {
                AdministrationModule: get(farm, "AdministrationModule", {}),
                BreedingModule: get(farm, "BreedingModule", {}),
                DevicesModule: get(farm, "DevicesModule", {})
            }
        }
    }
);


export const getFarmPackageModules = createSelector(
    [getSelectedFarm],
    (selectedFarm = {}) => {
        const {BreedingModule, DevicesModule, AdministrationModule} = selectedFarm;
        if (BreedingModule && BreedingModule.sows && BreedingModule.porkers && DevicesModule && AdministrationModule) {
            return {
                addOns: {
                    BreedingModule,
                    DevicesModule,
                    AdministrationModule,
                }
            }
        }
    }
);
