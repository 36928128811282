import {createSelector} from 'reselect'

const getShadow = (state, {DevID}) =>
    state.shadows.shadows.get(DevID);

//status czy pobiera czy nie zeby nie uzywac intervalow i timeoutow w komponentach jak neandertale
const getLoading = (state, {DevID}) =>
    (state.shadows.loading || {})[DevID];

export const makeGetShadowByDevice = () => {
    return createSelector(
        [getShadow, getLoading],
        (_shadow, _status) => {
            return {
                fetched: !!_shadow,
                fetching: !!_status,
                shadow: _shadow
            }
        }
    );
}
