import {createSelector} from "reselect";
import {getBuildingsMap} from "../buildingsSelector";
import {getDevices} from "../deviceSelector";
import Climate from "../../beans/devices/Climate";
import DispenserNRF from "../../beans/devices/DispenserNRF";
import {enhancedComparer} from "../../utils/TextUtils";
import {getSelectedObject, getShowAliases} from "./rowSelector";
import ClimateSK3 from "../../beans/devices/ClimateSK3";


export const getClimateData = createSelector([getShowAliases, getBuildingsMap, getDevices], (_showAlias, _buildingsMap, _devices) => {
    const climateData = [];
    _devices.forEach(device => {
        if (device instanceof Climate || device instanceof ClimateSK3 ||  (device instanceof DispenserNRF && device.hasTemperatureSensor() && !device.isIndividualTemperature())) {
            const dataRow = {
                device: device,
                placements: device.getPlacementArray().map(PlcmntID => _buildingsMap.get(PlcmntID)).filter(o => !!o)
            }
            dataRow.id = device.DevID;
            dataRow.name = device.getAddressString();
            if (_showAlias && device.Alias) dataRow.name = device.Alias;
            if (dataRow.placements.length) dataRow.name = dataRow.placements.map(o => o.name.slice(0).pop()).join(", ");
            climateData.push(dataRow);
        }
    })
    return climateData.sort((o1, o2) => enhancedComparer(o1.name, o2.name))
})


export const getSelected = createSelector([getClimateData, getSelectedObject], (_data, _selected) => {
    return _data.filter(o => (o.device instanceof Climate || o.device instanceof ClimateSK3) && !!_selected[o.id]);
})

const getCurves = (state) => state.settings.climateCurvesSK3;

export const getClimateCurves = createSelector([getCurves], (_curves)=>{
        return _curves;
})
