import {createSelector} from "reselect";
import {convertRowsToCycles} from "../utils/AnimalDocumentsUtils";

export const getSelectedAnimalForDocuments = state => state.animalDocuments.selectedItem;

export const getAnimalEvents = state => state.animalDocuments.events;

export const getAnimalEventsWithType = (state, type) => getAnimalEvents(state).filter(item => item.EvCode === type);

export const getCycleTable = state => state.animalDocuments.cycleTable;

export const getCycles = createSelector(
    [getCycleTable],
    cycleTable => convertRowsToCycles(cycleTable)
)