import {createSelector} from "reselect";
import _ from "lodash";
import {isUsingFakeData} from "../utils/SettingsUtils";
import {fakeGetSettlementList} from "../demo/others/fakeSettlement";

export const ChamberSettlement = {
    NO_SHOW: 0,
    SHOW_SEPARATION: 1,
    SHOW_SETTLEMENT: 2
};

const getSettlementsChamber = (state, {CID}) =>
    CID ? (isUsingFakeData() ? fakeGetSettlementList(state) : state.settlement.settlement).slice(0).filter(o => o.PlcmntID === CID) : [];

const getFetched = (state) =>
    isUsingFakeData() ? true : state.settlement.fetchedSettlement;

const getFetching = (state) =>
    isUsingFakeData() ? false : state.settlement.fetchingSettlement;

const getAllSettlements = (state) =>
    isUsingFakeData() ? fakeGetSettlementList(state) : state.settlement.settlement;


export const settlementCondition = (data, fetched = true) => {
    return fetched ? !_.isFinite(_.get(data, "DtaStartTime")) || (_.isFinite(_.get(data, "DtaEndTime")) && _.get(data, "DtaEndTime") < new Date().getTime()) ? ChamberSettlement.SHOW_SETTLEMENT : ChamberSettlement.SHOW_SEPARATION : ChamberSettlement.NO_SHOW
};

export const getSettlement = createSelector([getSettlementsChamber, getFetched, getFetching], (settlementList, fetched, fetching) => {
    const data = _.maxBy(settlementList.slice(0), o => o.DtaStartTime);
    return {
        fetched: fetched,
        fetching: fetching,
        data: data,
        state: settlementCondition(data, fetched)
    }
});

export const getSettlementList = createSelector([getSettlementsChamber], (settlementList) => {
    const result = settlementList.slice(0);
    result.sort((o1, o2) => o1.DtaStartTime - o2.DtaStartTime);
    return result;
});

export const getNewestSettlements = createSelector([getAllSettlements], (allSettlementsList) => {
    const result = allSettlementsList.slice(0);
    result.sort((o1, o2) => o2.DtaStartTime - o1.DtaStartTime);
    return _.uniqBy(result, o => o.PlcmntID)
});
