import {createSelector} from 'reselect'
import {isArray, isEmpty, isNil, isString, memoize} from "lodash";


const changePlacementToArray = memoize((placement) => isArray(placement) ? placement : isString(placement) ? [{PlcmntID: placement}] : [], (...args) => JSON.stringify(args))

const changeDevTypeToArray = memoize((DevType) => isArray(DevType) ? DevType : isString(DevType) ? [DevType] : [], (...args) => JSON.stringify(args))

export const getDevices = (state, props) =>
    state.farmDevices.devices;

const getDeviceType = (state, {DevType}) =>
    changeDevTypeToArray(DevType)

const getDeviceID = (state, {DevID}) =>
    DevID

const getPlacementIDs = (state, {PlcmntID}) => changePlacementToArray(PlcmntID)

export const makeGetDevicesByType = () => {
    return createSelector(
        [getDevices, getDeviceType],
        (_devices, _devTypes) => {
            return _devices.filter(d => _devTypes.includes(d.DevType));
        });
}

export const makeGetDeviceByID = () => {
    return createSelector(
        [getDevices, getDeviceID],
        (_devices, _devId) => {
            return isNil(_devId) ? null : _devices.find(d => d.DevID === _devId) || null;
        }
    );
}

export const makeGetDevicesInPlacement = () => {
    return createSelector([getDevices, getPlacementIDs, getDeviceType], (_deviceList, _placementIdList, _devTypes) => {
        //lokalna funkcja zmemoizowana dla przyspieszenia dzialania apki
        const hasLocation = memoize((aPlcmnts = [], bPlcmnts = []) => {
            for (let a of aPlcmnts) {
                for (let b of bPlcmnts) {
                    if (a.PlcmntID === b.PlcmntID) {
                        return true;
                    }
                }
            }
            return false;
        }, (...args) => JSON.stringify(args));

        return _deviceList.filter(device => {
            const devicePlacements = changePlacementToArray(device.PlcmntID);
            return (isEmpty(_devTypes) ? true : _devTypes.includes(device.DevType)) && hasLocation(devicePlacements, _placementIdList)
        })
    })
};
